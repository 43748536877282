.clearfix {
  clear: both; }

.clearfix:after {
  clear: both;
  content: "";
  display: table; }

.App {
  text-align: center;
  height: 100%;
  width: 100%; }
  .App-title {
    font-size: 4rem !important; }
    @media (max-width: 1200px) {
      .App-title {
        font-size: calc(1.525rem + 3.3vw)  !important; } }
  .App-header {
    background-color: #2B9DEA;
    padding: 20px; }
  .App-header-banner {
    width: 100% !important; }
  .App-menu {
    background-color: #2B9DEA !important;
    background: #2B9DEA !important; }
  .App-textColor-white {
    color: white !important; }
  .App-content {
    padding-top: 10px; }
  .App-skillset {
    margin: 0 !important;
    margin-top: 1em;
    margin-bottom: 1em;
    padding-left: 1em;
    padding-right: 1em; }
  .App-skillList {
    column-count: 2;
    column-gap: 40px;
    padding: 0 10px 0 0 !important; }
  .App-skillListItem {
    height: 60px; }
  .App-experience {
    margin: 0 !important;
    margin-top: 1em;
    margin-bottom: 1em;
    padding-left: 1em;
    padding-right: 1em;
    background-color: #2B9DEA !important;
    background: #2B9DEA !important;
    color: white !important; }
  .App-project-detail-table table {
    border: none !important; }
  .App-project-detail-table table tr {
    border: none !important; }
  .App-project-detail-table table td {
    border: none !important;
    padding-left: 0px !important;
    padding-top: 0px !important; }

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px; }

.App-avatarCenter {
  width: 210px;
  height: 210px;
  margin: auto; }

.App-avatarImageContainer {
  position: relative;
  width: 210px;
  height: 210px;
  padding: 6px;
  background-image: linear-gradient(to top, #F24D55 56%, #FF924F 57%, #FFC164 74%, #62C374 74%, #F24D55 34%, #00AEDA 33%);
  background-image: -o-linear-gradient(to top, #F24D55 56%, #FF924F 57%, #FFC164 74%, #62C374 74%, #F24D55 34%, #00AEDA 33%);
  background-image: -moz-linear-gradient(to top, #F24D55 56%, #FF924F 57%, #FFC164 74%, #62C374 74%, #F24D55 34%, #00AEDA 33%);
  background-image: -webkit-linear-gradient(to top, #F24D55 56%, #FF924F 57%, #FFC164 74%, #62C374 74%, #F24D55 34%, #00AEDA 33%);
  background-image: -ms-linear-gradient(to top, #F24D55 56%, #FF924F 57%, #FFC164 74%, #62C374 74%, #F24D55 34%, #00AEDA 33%);
  -webkit-animation-name: Rotate;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: Rotate;
  -moz-animation-duration: 1s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  animation-name: Rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  border-radius: 50%; }

.App-avatarImageContainer div {
  background-color: #333;
  color: #fff;
  font-size: 30px;
  text-align: center;
  min-height: 100%;
  border-radius: 50%; }

@keyframes Rotate {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }

@-webkit-keyframes Rotate {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }

@-moz-keyframes Rotate {
  from {
    -moz-transform: rotate(0deg); }
  to {
    -moz-transform: rotate(360deg); } }

@-ms-keyframes Rotate {
  from {
    -ms-transform: rotate(0deg); }
  to {
    -ms-transform: rotate(360deg); } }
